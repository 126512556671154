<script lang="ts">
  import Accordion, { Panel, Header, Content } from '@smui-extra/accordion';
  import type {
    ItemInfo,
    OrderList,
    OrderObj,
    PaymentMethod,
    Receipt,
    TypeOrderItem,
  } from 'scripts/sales/types';
  import ProductChoice from './child/ProductChoice.svelte';
  import Register from './child/Register.svelte';
  import ReservationList from './child/ReservationList.svelte';
  import { onMount } from 'svelte';
  import {
    calcReceipt,
    getChargeAmountFromReceipt,
  } from 'scripts/utils/price_utils';
  import { calendarState } from 'scripts/utils/states';
  import { dateToStr } from 'scripts/utils/date_utils';
  import type { TypeCalendarState } from 'scripts/utils/types';
  import { fetchGetRequest, getPaymentMethods } from 'scripts/utils/fetcher';
  import { routeProductManagerApp } from 'scripts/router';

  let calendarOpen = true;
  let orderOpen = !calendarOpen;
  // let receipt;
  let itemInfos: ItemInfo[];
  let paymentMethods: PaymentMethod[];
  let paymentMethod: PaymentMethod | undefined;
  let currentDate: Date;
  let orderItems: TypeOrderItem[] = [];
  let orderList: OrderList = [];
  let orderReferencePeriod = {
    start: new Date(),
    end: new Date(),
  };

  const defaultReceipt: Receipt = {
    '0%': { tax_price: 0, target_price: 0 },
    '8%': { tax_price: 0, target_price: 0 },
    '10%': { tax_price: 0, target_price: 0 },
  };

  const initialOrder: OrderObj = {
    pk: null,
    ne_order_id: null,
    sales_channel: null,
    customer_name: '',
    customer_name_kana: '',
    charge_amount: 0,
    status: 'PAI',
    ordered_date: new Date().toISOString(),
    shipped_date: '',
    delivery_number: '',
    orderItem_set: [],
    payment_method: null,
    amount_accept: 0,
    amount_change: 0,
    // sales_channel_name: '',
    receipt: { ...defaultReceipt },
  };

  let order = { ...initialOrder };

  const orderClick = (evt) => {
    order = evt.detail.order;
    orderOpen = true;
  };

  const updateOrderItems = (order: OrderObj) => {
    // console.log(order);
    order.orderItem_set.forEach(
      (oi) => (oi.get_subtotal = oi.quantity * oi.price_include_tax),
    );
    orderItems = order.orderItem_set;
    if (itemInfos && order) {
      order.receipt = calcReceipt(order, order.orderItem_set, itemInfos);
    }

    order.charge_amount = getChargeAmountFromReceipt(order.receipt);
    if (order.payment_method) {
      paymentMethod = paymentMethods
        ? paymentMethods.find((pm) => pm.pk === order.payment_method)
        : null;
      if (paymentMethod.name != '現金') {
        order.amount_accept = order.charge_amount;
      }
    }
    order.amount_change = order.amount_accept - order.charge_amount;
    order.payment_method = paymentMethod ? paymentMethod.pk : undefined;
    order = order;
    // console.log('order', order);
  };

  onMount(() => {
    getDatasets();
    currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  });

  const getDatasets = async () => {
    const res = await fetchGetRequest({
      endpoint: routeProductManagerApp.productsApi(),
      errorMessage: '商品一覧の取得に失敗しました。',
    });
    const paymentMethodRes = await getPaymentMethods();
    if (res.success) {
      itemInfos = res.data;
    } else alert(res.error);
    if (paymentMethodRes.success) {
      paymentMethods = paymentMethodRes.data;
    } else alert(paymentMethodRes.error);
    // console.log(paymentMethods);
  };

  const updateCalendarState = async (calState: TypeCalendarState) => {
    if (
      orderReferencePeriod.start != calState.start ||
      orderReferencePeriod.end != calState.end
    ) {
      orderReferencePeriod = {
        start: calState.start,
        end: calState.end,
      };
      updateOrderList(calState);
    }
  };

  const updateOrderList = async (calState: TypeCalendarState) => {
    const result = await fetchGetRequest({
      endpoint: routeProductManagerApp.productsApi(),
      errorMessage: '',
      params: {
        startDate: dateToStr(calState.start).YMDformat,
        endDate: dateToStr(calState.end).YMDformat,
        orderStatus: 'RES',
      },
    });
    // console.log('updateOrder', result);
    if (result.success) {
      orderList = result.data;
    } else alert(result.error);
  };

  const updateReceipt = (oi: TypeOrderItem[]) => {
    if (order) {
      order.orderItem_set = [...oi];
      order = order;
    }
  };

  const updatePaymentMethod = (pm: PaymentMethod) => {
    if (order) {
      // order.payment_method = pm?.pk;
      order = order;
    }
  };

  /** formSubmitされたら状態を初期化 */
  const onFormSubmit = (evt) => {
    if (evt.detail.success) {
      order = { ...initialOrder };
      paymentMethod = null;
      // console.log(order);
      updateOrderList($calendarState);
    }
  };

  $: updateCalendarState($calendarState);

  $: updatePaymentMethod(paymentMethod);
  $: updateOrderItems(order);
  $: updateReceipt(orderItems);
  // $: console.log('items', orderItems);
  // $: console.log('order', order);
</script>

<div class="row">
  <div class="col-12 my-4 my-lg-0 px-0">
    <div class="accordion-container">
      <Accordion>
        <Panel bind:open={calendarOpen}>
          <Header>予約一覧</Header>
          <Content>
            <ReservationList bind:orderList on:orderClick={orderClick} />
          </Content>
        </Panel>
        <Panel bind:open={orderOpen}>
          <Header>商品選択</Header>
          <Content style="padding-left: 0.5rem; padding-right: 0.5rem;">
            <ProductChoice bind:orderItems bind:itemInfos />
          </Content>
        </Panel>
      </Accordion>
    </div>
  </div>
  <div class="col-12 pe-4">
    <Register
      bind:order
      bind:paymentMethods
      bind:paymentMethod
      on:formSubmit={onFormSubmit}
    />
  </div>
</div>
