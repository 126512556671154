<script lang="ts">
  import Day from 'scripts/global_components/calendar/views/child/Day.svelte';

  export let startDate: Date;
  export let currentDate: Date;
  let week: Date[];

  const updateWeek = (startDate: Date) => {
    // 1週間のDateオブジェクトの配列
    week = Array.from(Array(7).keys(), (i) => {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      return date;
    });
  };

  $: updateWeek(startDate);
</script>

<div class="week">
  {#each week as date}
    <Day {date} bind:currentDate />
  {/each}
</div>

<style>
  div {
    display: flex;
    flex: 1;
  }
</style>
