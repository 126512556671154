<script lang="ts">
  import Radio from '@smui/radio';
  import FormField from '@smui/form-field';

  export let options = [];
  export let selected: any;
  export let valueKey: string = 'pk';
  export let labelKey: string = 'label';
</script>

<div class="radio-buttons">
  {#each options as option}
    <FormField>
      <Radio
        bind:group={selected}
        value={option[valueKey]}
        disabled={option.disabled}
      />
      <span slot="label">
        {option[labelKey]}{option.disabled ? ' (disabled)' : ''}
      </span>
    </FormField>
  {/each}
</div>
