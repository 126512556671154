<script lang="ts">
  import Calendar from 'scripts/global_components/calendar/Calendar.svelte';
  import type { OrderList } from '../types';
  import type { TypeTableConfig } from 'scripts/utils/types';
  import EditableDataTable from 'scripts/global_components/EditableDataTable.svelte';
  import { createEventDispatcher, onMount } from 'svelte';

  export let currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  export let orderList: OrderList = [];
  let todayOrders: OrderList = [];

  const updateTodayOrders = (current: Date, orderLis: OrderList) => {
    todayOrders = orderLis
      .filter((order) => {
        const nextDate = new Date(
          current.getFullYear(),
          current.getMonth(),
          current.getDate() + 1,
        );
        const orderDate = new Date(order.ordered_date);
        return orderDate >= current && nextDate > orderDate;
      })
      .sort(
        (prev, current) =>
          new Date(prev.ordered_date).getTime() -
          new Date(current.ordered_date).getTime(),
      );
    // console.log(current, todayOrders);
  };

  const orderTableCnfig: TypeTableConfig = {
    ordered_date: { label: '時間', type: 'time', editable: false },
    customer_name: { label: 'お客様名', type: 'string', editable: false },
  };

  const dispatch = createEventDispatcher();
  const orderClick = (evt) => {
    dispatch('orderClick', { order: evt.detail.item });
  };

  $: updateTodayOrders(currentDate, orderList);
</script>

<div class="cal">
  <Calendar viewType={'month'} bind:currentDate />
  <EditableDataTable
    on:trClick={orderClick}
    tableName={`${currentDate.getFullYear()}/ ${
      currentDate.getMonth() + 1
    }/ ${currentDate.getDate()}`}
    displayTableHeader={true}
    searchable={false}
    items={todayOrders}
    tableConfig={orderTableCnfig}
  />
</div>
