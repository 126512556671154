<script lang="ts">
  import type { FieldConfig } from 'scripts/utils/types';
  import type {
    ItemInfo,
    ItemInfoObj,
    TypeOrderItem,
  } from 'scripts/sales/types';
  import ItemChoiceAccordion from 'scripts/sales/child/ItemChoiceAccordion.svelte';
  import { defaultFieldConfig, defaultFieldOptions } from 'scripts/utils/utils';

  export let orderItems: TypeOrderItem[];
  export let itemInfos: ItemInfo[];
  let itemInfoObj: ItemInfoObj = {
    all: {
      theme_color: '#fff',
      category_slug: 'all',
      item_list: itemInfos,
    },
  };

  export let fieldConfigs: Record<string, FieldConfig> = {
    orderItems: {
      ...defaultFieldConfig,
      name: 'orderItems',
      options: {
        ...defaultFieldOptions,
        label: 'メニュー',
      },
    },
  };
  $: itemInfoObj.all.item_list = itemInfos;
  $: console.log(itemInfos);
</script>

{#if itemInfos?.length}
  <div>
    <ItemChoiceAccordion
      bind:config={fieldConfigs.orderItems}
      bind:orderItems
      bind:itemInfoObj
    />
  </div>
{/if}
