<script lang="ts">
  import MonthView from './views/MonthView.svelte';
  import { calendarState } from 'scripts/utils/states';
  import IconButton from '@smui/icon-button';
  import {
    getDateAfterMonth,
    getFirstDateOfMonth,
    makeNewReferencePeriod,
  } from 'scripts/utils/date_utils';

  export let viewType = 'month';
  export let currentDate: Date;

  const viewComponentMap = {
    month: MonthView,
  };

  const slideCalendarMonth = (monthDif: number) => {
    const currentMonthDate = new Date(
      $calendarState.currentYear,
      $calendarState.currentMonth,
      1,
    );
    const newMonthDate = getDateAfterMonth(currentMonthDate, monthDif);

    let period = {
      start: $calendarState.start,
      end: $calendarState.end,
    };
    if (
      newMonthDate <= getFirstDateOfMonth($calendarState.start) ||
      newMonthDate >= getFirstDateOfMonth($calendarState.end)
    ) {
      period = makeNewReferencePeriod(newMonthDate);
    }
    $calendarState = {
      ...period,
      currentYear: newMonthDate.getFullYear(),
      currentMonth: newMonthDate.getMonth(),
    };
  };
</script>

<svelte:head>
  <!-- Material Typography -->
  <link
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
    rel="stylesheet"
  />
</svelte:head>

<div class="calendar">
  <div class="calendar-header row">
    <div class="col-2 px-0 text-center">
      <button
        class="arrowButton text-muted"
        on:click={() => slideCalendarMonth(-1)}
      >
        <span class="material-icons">chevron_left</span>
      </button>
    </div>
    <div class="col-8">
      <h1 class="text-muted text-center my-0">
        {$calendarState.currentYear}年{$calendarState.currentMonth + 1}月
      </h1>
    </div>
    <div class="col-2 px-0 text-center">
      <button
        class="arrowButton text-muted"
        on:click={() => slideCalendarMonth(1)}
      >
        <span class="material-icons">chevron_right</span>
      </button>
    </div>
  </div>
  <div class="calendar-view">
    <svelte:component this={viewComponentMap[viewType]} bind:currentDate />
  </div>
</div>

<style>
  h1 {
    font-size: medium;
    font-weight: bold;
  }

  .arrowButton {
    background-color: white;
    border: 0;
  }
</style>
