<script lang="ts">
  import EditableDataTable from 'scripts/global_components/EditableDataTable.svelte';
  import { dateToStr } from 'scripts/utils/date_utils';
  import type {
    DispatchMessage,
    FieldConfig,
    FormConfig,
    TypeTableConfig,
  } from 'scripts/utils/types';
  import type { OrderObj } from 'scripts/sales/types';
  // import { orderItems } from 'scripts/reservations/reservation_state';
  import { defaultFieldConfig, defaultFieldOptions } from 'scripts/utils/utils';
  import ChoiceInput from 'scripts/global_components/input/ChoiceInput.svelte';
  import ModelDetailForm from 'scripts/global_components/ModelDetailForm.svelte';
  import NumberInput from 'scripts/global_components/input/NumberInput.svelte';
  import { createEventDispatcher, onMount } from 'svelte';
  import type { PaymentMethod } from 'scripts/sales/types';
  import RadioFilteredChoice from 'scripts/global_components/input/RadioFilteredChoice.svelte';
  import { getAndAppendOptions } from 'scripts/utils/form_utils';
  import { routeSalesApp } from 'scripts/router';

  export let order: OrderObj;
  export let paymentMethods: PaymentMethod[];
  export let paymentMethod: PaymentMethod | undefined;
  let amountChangeColor: 'red' | 'blue' = 'red';
  let endpoint = routeSalesApp.orderApi(order.pk);

  const itemTableConfig: TypeTableConfig = {
    name: { label: '商品名', type: 'string', editable: false },
    quantity: {
      label: '数量',
      type: 'number',
      editable: true,
      inputStyle: 'width: 5rem;',
    },
    price_include_tax: {
      label: '価格',
      type: 'number',
      editable: true,
      inputStyle: 'width: 6rem;',
    },
    get_subtotal: { label: '小計', type: 'number', editable: false },
  };

  let formConfig: FormConfig = {
    Row_1: {
      sales_channel: {
        ...defaultFieldConfig,
        name: 'sales_channel',
        component: ChoiceInput,
        inputClass: 'col-12 col-md-10 col-lg-6',
        initialValue: undefined,
      },
      status: {
        ...defaultFieldConfig,
        name: 'status',
        component: ChoiceInput,
        inputClass: 'col-12 col-md-10 col-lg-6',
        initialValue: undefined,
      },
    },
  };

  let chargeAmountConfig: FieldConfig = {
    ...defaultFieldConfig,
    placeHolder: '',
    options: {
      ...defaultFieldOptions,
      type: 'integer',
      label: '金額',
      min_value: 0,
      max_value: 2147483647,
    },
  };

  onMount(async () => {
    const optionRes = await getAndAppendOptions(endpoint, formConfig);
    if (optionRes.success) {
      formConfig = optionRes.data.formConfig;
    }
  });

  const confirmFunction = () => {
    // console.log('order', order);
    if (!order.payment_method) {
      const res = confirm('支払い方法が選択されていません。よろしいですか？');
      if (!res) return res;
    }
    if (order.status === 'PAI' && order.charge_amount > order.amount_accept) {
      alert('預り金額に合計金額よりも少ない金額が入力されています。');
      return false;
    } else if (order.status === 'RES') {
      const res = confirm(
        'ステータスが予約済みのままの場合、売上は計上されません。よろしいですか？',
      );
      if (!res) return res;
    }
    if (order.orderItem_set.length) {
      return order.orderItem_set;
    } else {
      alert('商品が選択されていません。');
      return false;
    }
  };
  // console.log(SALON_SLUG);

  const updateAmountChange = (order: OrderObj) => {
    order.amount_change >= 0
      ? (amountChangeColor = 'blue')
      : (amountChangeColor = 'red');
  };

  const dispatch = createEventDispatcher();
  /** 登録、削除など、フォームで行う処理が完了した際に発火するイベント */
  const onProcessEnd = (evt) => {
    const detail: DispatchMessage = evt.detail;
    dispatch('formSubmit', detail);
  };

  $: updateAmountChange(order);
</script>

{#if order}
  <div style="">
    <span>{dateToStr(new Date()).YMDJPformat}</span>
    <span>{order.customer_name}</span>
    <EditableDataTable
      bind:items={order.orderItem_set}
      tableConfig={itemTableConfig}
      searchable={false}
      displayPagination={false}
    >
      <tr class="mdc-data-table__row">
        <td colspan="4" class="mdc-data-table__cell py-4">
          {#each Object.entries(order.receipt) as [key, tax_obj]}
            {#if tax_obj.tax_price}
              <div class="row pe-2" style="font-size: 0.8rem;">
                <div class="col-5">(内税{key}対象額)</div>
                <div class="col-7 text-end">
                  ¥{tax_obj.target_price?.toLocaleString()}
                </div>
              </div>
              <div class="row pe-2" style="font-size: 0.8rem;">
                <div class="col-5">内税{key}</div>
                <div class="col-7 text-end">
                  ¥{tax_obj.tax_price?.toLocaleString()}
                </div>
              </div>
            {/if}
          {/each}
          <hr />
          <div class="row pe-2 text-muted font-weight-bold">
            <div class="col-5">合計</div>
            <div class="col-7 text-end">
              ¥{order.charge_amount ? order.charge_amount.toLocaleString() : 0}
            </div>
          </div>
          <div class="mt-4">
            <div class="text-muted font-weight-bold">支払い方法</div>
            <div style="padding-left: 1rem">
              <RadioFilteredChoice
                bind:selected={paymentMethod}
                bind:choices={paymentMethods}
                parentKey="category"
                parentLabel="get_category_display"
                childKey="pk"
                childLabel="name"
              />
            </div>
          </div>
          <div
            class="row text-muted font-weight-bold d-flex align-items-center"
          >
            <div class="col-5" style="vertical-align: middle">支払い金額</div>
            <div class="col-7 text-end">
              <NumberInput
                bind:value={order.amount_accept}
                config={chargeAmountConfig}
                inputStyle="text-align:right; padding:0px; vertical-align: middle;"
              />
            </div>
          </div>
          <div class="row pe-2 text-muted font-weight-bold">
            <div class="col-5">お釣り</div>
            <div class="col-7 text-end" style="color: {amountChangeColor}">
              ¥{order.amount_change.toLocaleString()}
            </div>
          </div>
        </td>
      </tr>
    </EditableDataTable>
    <ModelDetailForm
      modelName="会計"
      bind:formConfig
      {endpoint}
      bind:state={order}
      primaryKey="pk"
      cancelButtonDisplay={true}
      submitButtonLabel="会計"
      {confirmFunction}
      on:processEnd={onProcessEnd}
    />
  </div>
{/if}
