<script lang="ts">
  import { fetchGetRequest } from 'scripts/utils/fetcher';
  import type { OrderObj } from './types';
  import { routeSalesApp } from 'scripts/router';
  import { onMount } from 'svelte';
  import OrderTable from './child/OrderTable.svelte';
  import { dateToStr } from 'scripts/utils/date_utils';
  export let params;
  let order: OrderObj;

  const getOrder = async () => {
    let fetchRes = await fetchGetRequest({
      endpoint: routeSalesApp.orderApi(params.id),
      errorMessage: 'OrderDetailの取得に失敗しました。',
    });
    console.log(fetchRes);

    if (fetchRes.success) order = fetchRes.data;
    else alert(fetchRes.error);
  };
  onMount(() => {
    getOrder();
  });

  $: console.log(params, order);
</script>

{#if order}
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">基本情報</div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <h5 class="border-bottom">注文者</h5>
              <div class="mt-3 ps-3">
                <div>注文ID : {order.pk}</div>
                <div>
                  注文者 : {order.customer_name} ({order.customer_name_kana})
                </div>
                <div>NE受注番号 : {order.ne_order_id || ''}</div>
                <div>
                  NE受注詳細 : {#if order.ne_order_id}<a
                      href={`https://main.next-engine.com/Userjyuchu/jyuchuInp?kensaku_denpyo_no=${order.ne_order_id}`}
                    >
                      受注伝票へ
                    </a>
                  {/if}
                </div>
              </div>
            </div>
            <div class="col-6 border-start">
              <h5 class="border-bottom">
                ステータス
                {#if order.status == 'CAN' || order.status == 'RER'}
                  <span class="text-danger">{order.status}</span>
                {:else}
                  <span>{order.status}</span>
                {/if}
              </h5>
              <div class="mt-3 ps-3">
                <div>
                  受注日 : {dateToStr(new Date(order.ordered_date))
                    .YMDhmJPformat || ''}
                </div>
                <div>発送日 : {order.shipped_date || ''}</div>
                <div>
                  発送番号 : {#if order.delivery_number}{order.delivery_number}{/if}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h5 class="border-bottom">受注詳細</h5>
              <div class="mt-3 ps-3">
                <OrderTable {order} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
