<script lang="ts">
  import { calendarState } from 'scripts/utils/states';
  import { isSameDay } from 'scripts/utils/date_utils';

  export let date: Date;
  export let currentDate: Date;
  let bgcolor = '';

  const isCurrentMonth = (date: Date) =>
    date.getMonth() === $calendarState.currentMonth;

  /* const isToday = (date: Date) => {
    const today = new Date();
    return isSameDay(today, date);
  };*/

  const isCurrentDate = (currentDate: Date) => {
    bgcolor = isSameDay(currentDate, date) ? '#ff69' : '';
  };

  const btnClick = () => {
    currentDate = date;
  };

  $: isCurrentDate(currentDate);
</script>

<div style:background-color={bgcolor}>
  <button
    class="date-button primary-color"
    style="opacity: {isCurrentMonth(date) ? 1 : 0.5};"
    style:background-color={bgcolor}
    on:click={btnClick}
  >
    {date.getDate()}
  </button>
</div>

<style>
  .date-button {
    border-width: 0px;
    background-color: #fff;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  div {
    flex: 1;
    border: 1px solid #ccc;
    border-top-width: 0;
    text-align: right;
    padding: 0 0.3rem;
  }
  div:nth-child(n + 2) {
    border-left-width: 0;
  }
</style>
