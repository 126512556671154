<script lang="ts">
  import { calendarState } from 'scripts/utils/states';
  import type { TypeCalendarState } from 'scripts/utils/types';
  import Week from './child/Week.svelte';

  let sundays: Date[];
  export let currentDate: Date;

  const drawCalendar = (calendarState: TypeCalendarState) => {
    // 今月1日
    const firstDayOfMonth = new Date(
      calendarState.currentYear,
      calendarState.currentMonth,
      1,
    );

    // 1日が属する週の日曜日
    const firstDayOfFirstWeek = new Date(firstDayOfMonth);
    firstDayOfFirstWeek.setDate(1 - firstDayOfMonth.getDay());

    // 表示するすべての日曜日
    sundays = Array.from(Array(6).keys(), (i) => {
      const sunday = new Date(firstDayOfFirstWeek);
      sunday.setDate(sunday.getDate() + 7 * i);
      return sunday;
    }); /*.filter(
      (date, i) => i === 0 || date.getMonth() === firstDayOfMonth.getMonth(),
    );*/
  };

  $: drawCalendar($calendarState);
</script>

<div class="month-view">
  <div class="week-header">
    {#each ['日', '月', '火', '水', '木', '金', '土'] as dow}
      <div class="dow">{dow}</div>
    {/each}
  </div>
  <div class="days">
    {#each sundays as sunday}
      <Week bind:startDate={sunday} bind:currentDate />
    {/each}
  </div>
</div>

<style>
  .month-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 18px;
    text-align: center;
  }
  .week-header {
    display: flex;
  }
  .dow {
    flex: 1;
    border: 1px solid #cccccc;
  }
  .dow:nth-child(n + 2) {
    border-left-width: 0;
  }
  .days {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
</style>
