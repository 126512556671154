<script lang="ts">
  import PanelHead from 'scripts/global_components/PanelHead.svelte';
  import ObjCheckBox from 'scripts/global_components/ObjCheckBox.svelte';
  import OriginalPanel from 'scripts/global_components/OriginalPanel.svelte';
  import SlideCollapse from 'scripts/global_components/SlideCollapse.svelte';
  import InvalidMessage from 'scripts/global_components/input/InvalidMessage.svelte';
  import type { FieldConfig } from 'scripts/utils/types';
  import type {
    TypeOrderItem,
    ItemInfoObj,
    ItemInfo,
  } from 'scripts/sales/types';
  export let config: FieldConfig;
  export let itemInfoObj: ItemInfoObj = {};
  export let orderItems: TypeOrderItem[];
  export let selectedItemObj: Record<string, ItemInfo[]> = {};
  let expands: Record<string, boolean> = {};
  let productMessage = '';

  const validate = (items) => {
    config.is_valid = items.length > 0;
    // console.log(config, items, orderItems);
    productMessage = items.length ? '' : '予約メニューを選択してください。';
  };

  Object.keys(itemInfoObj).forEach((k) => (expands[k] = false));
  const labels = ['name', 'price_include_tax'];

  /** selectedが変更された際にOrderItemを更新 */
  const updateOrderItems = (selected: Record<string, ItemInfo[]>) => {
    let selectedInfoLis: ItemInfo[] = [];
    Object.values(selected).forEach(
      (i) => (selectedInfoLis = [...selectedInfoLis, ...i]),
    );
    let tempOrderItems: TypeOrderItem[] = [];
    tempOrderItems = selectedInfoLis.map((item: ItemInfo) => {
      return {
        pk: undefined,
        item: item.pk,
        code: item.code,
        name: item.name,
        base_price: item.price,
        price_include_tax: item.price_include_tax,
        quantity: 1,
        // 既存のorderitemがある場合は既存値で書き換え
        ...orderItems.find((oi) => oi.item === item.pk),
      };
    });
    orderItems = tempOrderItems;
    // console.log('updateOrderItems');
  };

  /** orderが新しく選択された場合など、OrderItemが更新された場合にselectedItemObjを更新 */
  const makeSelectedItemList = (newOrderItems: TypeOrderItem[]) => {
    const orderItemCodes = newOrderItems.map((oi) => oi.code);
    const tempObj: Record<string, ItemInfo[]> = {};
    Object.entries(itemInfoObj).map(([category, value]) => {
      tempObj[category] = value.item_list.filter((item: ItemInfo) =>
        orderItemCodes.includes(item.code),
      );
    });
    selectedItemObj = tempObj;
    // console.log('newOrderItems', newOrderItems, selectedItemObj);
  };

  $: updateOrderItems(selectedItemObj);
  $: makeSelectedItemList(orderItems);
  $: validate(orderItems);
</script>

<div id="itemChoiceAccordion">
  <!--calendar.tsで生成-->
  <SlideCollapse expand={true}>
    <div class="accordion-container p-1">
      {#each Object.entries(itemInfoObj) as [category, itemsContainer]}
        {#if itemsContainer.item_list.length}
          <OriginalPanel>
            <PanelHead
              bind:display={expands[category]}
              color={itemsContainer.theme_color}
            >
              {category}
            </PanelHead>
            <SlideCollapse expand={expands[category]}>
              <ObjCheckBox
                objList={itemsContainer.item_list}
                label_keys={labels}
                bind:selectedObjList={selectedItemObj[category]}
              />
            </SlideCollapse>
          </OriginalPanel>
        {/if}
      {/each}
      <InvalidMessage
        message={productMessage}
        focus={config.is_valid}
        is_valid={config.is_valid}
      />
    </div>
  </SlideCollapse>
</div>
