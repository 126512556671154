<script lang="ts">
  import RadioButtonSelect from 'scripts/global_components/input/RadioButtonSelect.svelte';
  import type { FieldConfig, ChoiceValue } from 'scripts/utils/types';
  import { defaultFieldConfig, defaultFieldOptions } from 'scripts/utils/utils';
  import ChoiceInput from './ChoiceInput.svelte';

  export let choices: Record<string, any>[] = [];
  export let selected;
  export let childKey: string; // selectedを格納するchoicesのkey
  export let childLabel: string;
  let parentValue; // choicesを絞り込むための値
  export let parentKey: string; // parentValueを格納するchoicesのkey
  export let parentLabel: string;

  let parentChoices: Record<string, any>[] = [];
  let childValue;
  let childChoices: ChoiceValue[] = [];
  let childConfig: FieldConfig = {
    ...defaultFieldConfig,
    placeHolder: '',
    options: {
      ...defaultFieldOptions,
      label: '支払い方法',
      choices: [],
    },
  };

  // $: console.log(choices, parentValue);

  /** choicesをフィルターする要素（parentKey）の値のリスト */
  const updateParentChoices = (choices: Record<string, any>[]) => {
    parentChoices = [];
    const choiceValues = [];
    choices.forEach((choice) => {
      if (!choiceValues.includes(choice[parentKey])) {
        choiceValues.push(choice[parentKey]);
        parentChoices.push({
          value: choice[parentKey],
          label: choice[parentLabel],
        });
      }
    });
    // console.log('parentChoices', parentChoices, choiceValues);
  };

  const updateValue = (parentVal, childVal) => {
    if (parentVal && parentValue != parentVal) parentValue = parentVal;

    childChoices = choices.map((choice) => {
      return {
        value: choice[childKey],
        display_name: choice[childLabel],
        hidden: choice[parentKey] == parentVal ? false : true,
      };
    });
    childConfig.options.choices = childChoices;
    childConfig = childConfig;
    // console.log('childchoices', childChoices, parentVal, childValue);

    if (childVal) {
      const current = choices.find((c) => c[childKey] === childVal);
      selected = childChoices.find((c) => c.value == childVal).hidden
        ? null
        : current;
      if (!selected) childValue = null;
    } // else selected = null;
    //console.log('selected', selected);
  };

  const updateSelected = (selectedVal) => {
    if (selectedVal) {
      parentValue = selectedVal[parentKey];
      childValue = selectedVal[childKey];
    } else {
      // console.log('selectedVal', selectedVal);
      childValue = null;
    }
  };
  $: updateParentChoices(choices);
  $: updateSelected(selected);
  $: updateValue(parentValue, childValue);
</script>

<RadioButtonSelect
  bind:options={parentChoices}
  bind:selected={parentValue}
  valueKey="value"
  labelKey="label"
/>
<ChoiceInput bind:config={childConfig} bind:value={childValue} />
