<script lang="ts">
  /** 入力されたオブジェクトのリストからチェックボックスのリストを生成。
   * objList: 選択肢になるオブジェクトを全て含むリスト
   * value_key: valueを取得するフィールド
   * label_keys: ラベルとして取得するフィールド
   * selectedValues: 選択されているオブジェクトのvalue_keyの値のリスト
   */
  export let label_keys: (string | number)[];
  export let objList: Record<string, any>[] = [];
  export let selectedObjList: Record<string, any>[] = []; // objListのうち、checked==trueのもの
</script>

{#if objList.length}
  {#each objList as obj}
    <div class="productCheck">
      <label>
        <input type="checkbox" bind:group={selectedObjList} value={obj} />
        {#each label_keys as key}
          {obj[key]}
        {/each}
      </label>
    </div>
  {/each}
{/if}

<style>
  .productCheck {
    padding: 0.5rem 1rem;
  }
</style>
