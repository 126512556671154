<script lang="ts">
  import { fetchGetRequest } from 'scripts/utils/fetcher';
  import type { OrderList } from './types';
  import { routeSalesApp } from 'scripts/router';
  import { onMount } from 'svelte';
  import type {
    ChoiceDisplayMap,
    FormConfig,
    TypeTableConfig,
  } from 'scripts/utils/types';
  import Spinner from 'scripts/global_components/child_components/Spinner.svelte';
  import EditableDataTable from 'scripts/global_components/EditableDataTable.svelte';
  import { defaultFieldConfig } from 'scripts/utils/utils';
  import TextInput from 'scripts/global_components/input/TextInput.svelte';
  import ChoiceInput from 'scripts/global_components/input/ChoiceInput.svelte';
  import NumberInput from 'scripts/global_components/input/NumberInput.svelte';
  import { navigate } from 'svelte-routing';

  let orderList: OrderList = [];

  let spinnerDisplay = false;
  let choiceDisplayMap: ChoiceDisplayMap = {};
  let formOpen = false;
  const endpoint = routeSalesApp.orderApi();

  let orderTableConfig: TypeTableConfig = {
    pk: { label: 'ID', type: 'string', editable: false, hidden: true },
    ne_order_id: { label: '受注番号', type: 'number', editable: false },
    // get_stock_type_display: { label: '区分', type: 'string', editable: false },
    sales_channel: {
      label: '販売先',
      type: 'choices',
      editable: false,
      choices: [],
      hidden: true,
    },
    customer_name: { label: '顧客名', type: 'string', editable: false },
    customer_name_kana: { label: '仮名', type: 'string', editable: false },
    status: {
      label: 'ステータス',
      type: 'choices',
      editable: false,
      choices: [],
    },
    charge_amount: {
      label: '支払金額',
      type: 'number',
      editable: false,
    },
    ordered_date: {
      label: '受注日',
      type: 'date',
      editable: false,
    },
    shipped_date: {
      label: '発送日',
      type: 'date',
      editable: false,
      hidden: true,
    },
    delivery_number: {
      label: '配送番号',
      type: 'string',
      editable: false,
      hidden: true,
    },
  };

  let formConfig: FormConfig = {
    Row_1: {
      ne_order_id: {
        ...defaultFieldConfig,
        name: 'ne_order_id',
        component: NumberInput,
        inputClass: 'col-12 col-md-3',
      },
      sales_channel: {
        ...defaultFieldConfig,
        name: 'sales_channel',
        component: ChoiceInput,
        inputClass: 'col-12 col-md-5',
        initialValue: undefined,
      },
    },
    Row_2: {
      customer_name_kana: {
        ...defaultFieldConfig,
        name: 'customer_name_kana',
        component: TextInput,
        inputClass: 'col-12 col-md-10',
      },
    },
    Row_3: {
      customer_name: {
        ...defaultFieldConfig,
        name: 'customer_name',
        component: ChoiceInput,
        inputClass: 'col-12 col-md-10',
      },
    },
    Row_4: {
      charge_amount: {
        ...defaultFieldConfig,
        name: 'charge_amount',
        placeHolder: '1000',
        component: NumberInput,
        inputClass: 'col-7 col-md-6',
      },
      status: {
        ...defaultFieldConfig,
        name: 'status',
        component: ChoiceInput,
        inputClass: 'col-5 col-md-5',
        initialValue: undefined,
      },
    },
    Row_5: {
      ordered_date: {
        ...defaultFieldConfig,
        name: 'ordered_date',
        component: ChoiceInput,
        inputClass: 'col-12 col-md-6',
      },
      shipped_date: {
        ...defaultFieldConfig,
        name: 'shipped_date',
        component: ChoiceInput,
        inputClass: 'col-12 col-md-6',
      },
    },
  };

  const makeDatasets = async () => {
    let fetchRes = await fetchGetRequest({
      endpoint: endpoint,
      errorMessage: 'OrderListの取得に失敗しました。',
    });

    if (fetchRes.success) orderList = fetchRes.data;
    else alert(fetchRes.error);
  };

  onMount(() => {
    makeDatasets();
  });
</script>

<svelte:head>
  <!-- Material Typography -->
  <link
    rel="stylesheet"
    href="https://unpkg.com/@material/typography@13.0.0/dist/mdc.typography.css"
  />
  <link
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
    rel="stylesheet"
  />
</svelte:head>

<div class="row p-2">
  <div class="col-12">
    <EditableDataTable
      on:trClick={(e) => navigate(`${e.detail.item.pk}`)}
      items={orderList}
      bind:tableConfig={orderTableConfig}
      bind:choiceDisplayMap
      primaryKey="pk"
      csvEnable={false}
    />
  </div>
</div>
<Spinner bind:display={spinnerDisplay} />
<div class="d-flex justify-content-center">
  <button class="btn btn-primary mx-auto" on:click={(e) => navigate(`cashier`)}
    >新規売上登録</button
  >
</div>

<!--ModalShell btnLabel="新規売上登録"><CashierHome /></ModalShell-->
