<script lang="ts">
  import PanelHead from './PanelHead.svelte';
  import SlideCollapse from './SlideCollapse.svelte';

  export let borderWidth = '1';
</script>

<div style="border-width: {borderWidth}px" class="original-panel">
  <slot />
</div>

<style>
  .original-panel {
    border: solid #cfcfcf;
    border-radius: 5px;
  }
</style>
