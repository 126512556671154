/** 入力値の商（切り捨て）と剰余を求める */
export const divmod = (num: number, divider: number) => {
    const div = Math.floor(num / divider)
    const mod = num % divider
    return [div, mod]
}

/** startNumからstep分増加する数値のArrayをlengthの長さ分作成する */
export const createNumArray = (start: number, step: number, length: number) => {
    const arr = []
    for (let i = 0; i < length; i++) arr.push(start + step * i);
    return arr
} 