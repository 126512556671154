import SalesRouter from 'scripts/sales/SalesRouter.svelte'
import { APP_NAME, VIEW_NAME } from "scripts/router";
let app = undefined

if (APP_NAME === 'sales') {
    app = new SalesRouter({
        target: document.querySelector('.sv-component')!,
    });
}

export default app;