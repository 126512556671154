<script lang="ts">
  import type { OrderObj } from '../types';

  export let order: OrderObj;
</script>

{#if order}
  <div class="col-11 px-auto">
    <div class="row">
      {#each order.orderItem_set as orderItem}
        <div class="row">
          <div class="col-8">{orderItem.name}</div>
          <div class="col-4 text-end pe-2">
            ¥{(orderItem.base_price * orderItem.quantity).toLocaleString()}
          </div>
        </div>
      {/each}
      <hr class="mt-3" />
      {#if order.receipt}
        {#each Object.entries(order.receipt) as [key, tax_obj]}
          {#if tax_obj.tax_price}
            <div class="row pe-2" style="font-size: 0.8rem;">
              <div class="col-5">(内税{key}対象額)</div>
              <div class="col-7 text-end">
                ¥{tax_obj.target_price.toLocaleString()}
              </div>
            </div>
            <div class="row pe-2" style="font-size: 0.8rem;">
              <div class="col-5">内税{key}</div>
              <div class="col-7 text-end">
                ¥{tax_obj.tax_price.toLocaleString()}
              </div>
            </div>
          {/if}
        {/each}
      {/if}

      <div class="row pe-2 text-muted font-weight-bold">
        <div class="col-5">合計</div>
        <div class="col-7 text-end">
          ¥{order.charge_amount.toLocaleString()}
        </div>
      </div>
    </div>
  </div>
{/if}
